import React from 'react';
import './homePage.style.scss';
import logo from '../image/logo.png';
import sytem from '../image/Optimized-System.svg';
import reduce from '../image/Reduce-SAAS-Cost.svg';
import secure from '../image/Secure-Data.svg';
import section from '../image/section.svg';
import asset from '../image/Asset-Management.svg';
const homePage = () => {
  return (
    // <div className="homePage">
    //   <Directory />
    //  </div>
    <div>
      <div className="container-fluid">
        <div className="row dv-style">
          <div className="col-md-3">
            <a href="#">
              <img src={logo} className="img-style" />
            </a>
          </div>
          <div className="col-md-4">
          </div>
          <div className="col-md-5">
            <div style={{ textAlign: 'right' }}>
              <a href="#contactus"><button className="btn2">Contact Us</button></a>
            </div>
          </div>
        </div>
      </div>
      <div>


        <div className="container-fluid bg-img">
          <div className="row">
            <div className="col-md-12">
              <div style={{ margin: '200px 0px 0px 50px' }}>
                <div>
                  <p className="p-style1">
                    The B2B marketplace  <br />
                  for IT Services Industry.
              </p>
                  <p className="fnt-style">
                    Seamlessly design, deliver and manage IT services <br />
                  on one connected platform.              </p>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <a href="#contactus">
                      <button className="btn-style">Request Demo</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-dv">
        <div className="row">
          <div className="col-md-12">
            <div>
              <h3 className="hh-heading" style={{ textAlign: 'center' }}>Value Proposition</h3>
              <p className="p-style" style={{ textAlign: 'center' }}>
                Experience a new way to design, launch and support IT Services.<br />
              </p>
              <div className="row">
                <div className="col-md-4">
                  <div>
                    <div style={{ textAlign: 'center' }}>
                      <img src={reduce} style={{ width: '30%' }} />
                    </div>
                    <h1 className="main-h">Deliver happy customer experience</h1>
                    <p className="scnd-p">If a customer journey is displeasing, you end up losing them to your competition.
                    To provide a happy customer experience, you need to create value which in the IT world can be created if you are able to design and deliver IT services quickly and seamlessly.
                    Nuone’s ecosystem empowers you with ready-to-use SaaS tools which can be used as a utility.
                    B2B suppliers can now design, deliver, & support; and B2B buyers can procure, deploy and manage IT products and services all from one platform.
                </p>
                  </div>
                  <div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div>
                    <div style={{ textAlign: 'center' }}>
                      <img src={sytem} style={{ width: '30%' }} />
                    </div>
                    <h1 className="main-h">Connecting the broken bridge</h1>
                    <p className="scnd-p">When IT services are disrupted, business continuity is hampered and companies lose thousands to millions of dollars in terms of revenue and resources.
                    With Nuone’s integrated service desk, organizations can now connect internal technical teams with external support teams to interact in real time, monitor SLA and escalations, and resolve IT problems quicker and resume businesses activities faster.

                </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div>
                    <div style={{ textAlign: 'center' }}>
                      <img src={secure} style={{ width: '30%' }} />
                    </div>
                    <h1 className="main-h">Cross-convert roles seamlessly</h1>
                    <p className="scnd-p">Roles of supplier and buyer cannot be constant.
                    In the B2B world, suppliers need to procure products and services from their upstream providers, while buyers may have a need to resell IT products and services to their downstream customers.
                    Using Nuone’s switch role functionality, you can now switch roles between supplier and buyer and start selling or buying on the same platform seamlessly.

                </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row asst-mangment">
          <div className="col-md-6">
            <div style={{ margin: '100px 0px 0px 50px' }}>
              <h1 className="hh-heading">What is Nuone?</h1>
              <p className="ppp">Nuone is a SaaS enabled B2B marketplace which provides organizations with a unique capability to design, deliver and provide support for IT products and services, all from one connected platform.
              We take care of providing all the necessary tools and technologies you need for your IT services needs, so can focus on growing your business, create value and provide a delightful experience for your customers.
</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="container" style={{ textAlign: 'center' }}>
              <img src={asset} className="asst-img" />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row" style={{ background: '#2c2c2c', padding: '100px 100px 50px 50px' }}>
          <div className="col-md-4">
            <div>
              <div style={{ textAlign: 'center' }}>
                <img src={reduce} style={{ width: '30%' }} />
              </div>
              <h1 className="main-h">Marketplace</h1>
              <p className="scnd-p">In B2B, if you are a supplier, you need buyers to sell your products or services to.
              If you are a buyer, you need several suppliers, to compare and procure the best products or services.
              With Nuone marketplace, we are bringing B2B suppliers and buyers of all IT software, products and services together, so they can connect, interact and transact on the same platform.

            </p>
            </div>
            <div>
            </div>
          </div>
          <div className="col-md-4">
            <div style={{ marginTop: '150px' }}>
              <div>
                <div style={{ textAlign: 'center' }}>
                  <img src={sytem} style={{ width: '30%' }} />
                </div>
                <h1 className="main-h">SaaS factory</h1>
                <p className="scnd-p">B2B suppliers and buyers, each of them need several tools and software to run their IT services business. The current solution of procuring a patchwork of silo disconnected software from vertically, industry specific vendors, is unworkable as it causes lot of friction and delays in the design, launch and support of IT services.
                Nuone provides a sundry of SaaS based tools which suppliers and buyers can use as a utility as and when they need without worrying about procuring external vendor solutions.

              </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div style={{ marginTop: '300px' }}>
              <div>
                <div style={{ textAlign: 'center' }}>
                  <img src={secure} style={{ width: '30%' }} />
                </div>
                <h1 className="main-h">On-demand workforce</h1>
                <p className="scnd-p">Businesses today have become immensely competitive. You need to keep your customers happy and have to meet SLA requirements. In the end this is IT and it fails and you need to be able to quickly get back to business by solving issues to reduce the downtime of your business and IT services.
                Using Nuone’s on-demand consultants on the marketplace, you can hire resources based on your business requirement, proximity and legal requirements without leaving the platform.

              </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-container container-fluid" id="contactus">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="cntact-pge">Contact Us</h1>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Name</label>
                <input type="text" className="form-control input-style2" id="exampleInputPassword1" placeholder="Enter Your Name" />
              </div>
              <div className="form-group" style={{ marginBottom: '20px' }}>
                <label htmlFor="exampleInputEmail1">Email address</label>
                <input type="email" className="form-control input-style2" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
                <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone
                else.</small>
              </div>
              <div className="form-check">
                <input type="checkbox" className="form-check-input chck-bx-style" id="exampleCheck1" />
                <label className="form-check-label" htmlFor="exampleCheck1" style={{ color: '#fff', margin: '10px 0px 0px 10px' }}>Remember Me</label>
              </div>
              <button type="submit" className="submit-btnn">Submit</button>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid" style={{ background: '#000000d9' }}>
        <div className="row">
          <div className="col-md-4">
            <p className="paragraph-style socil-cnt">Social Connect:
</p>
            <div>
              <a href="https://www.instagram.com/connect2startem/" target="blank"> <i className="fab fa-facebook-f fnt-awsm-icn" /></a>
              <a href="https://twitter.com/connect2startem" target="blank"><i className="fab fa-twitter icn2" /></a>
              <a href="https://www.linkedin.com/company/startem" target="blank"><i className="fab fa-linkedin-in icn2" /></a>
              <a href="https://www.instagram.com/connect2startem/" target="blank"> <i className="fab fa-instagram icn2" /></a>
            </div>
          </div>
          <div className="col-md-4">
            <div className="footer22">
              <img src={logo} className="img-style" style={{ width: '30%' }} />
              <p className="txt-style">16192 Coastal Highway, Lewes, Delaware 19958, County of Sussex.</p>
              <p className="txt-style" style={{ marginTop: '-5px' }}>info@nuone.io</p>
            </div>
          </div>
          <div className="col-md-4">
            <div style={{ float: 'right' }}>
              <p className="fnt-style2">Investor Relation</p>
              <div className="foter-design">
                <div className="form-group" style={{ marginRight: ' 30px' }}>
                  <label>Name</label>
                  <input type="text" className="form-control innpt" placeholder="Enter Your Name" />
                </div>
                <br />
                <div className="form-group" >
                  <label htmlFor="exampleInputEmail1">Email address</label>
                  <input type="email" className="form-control innpt" placeholder="Enter email" />
                </div>
                <button className="subscribe-btn">Investor Contact</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default homePage;


